import { Design } from '@amedia/brick-themes';

import { Skin } from '../../types.js';

import { footerContainerStyle } from './footer-style.js';

export default function footerTemplate({
  tipUsMarkup = '',
  bylineMarkup = '',
  skin,
  theme,
}: {
  tipUsMarkup?: string;
  bylineMarkup?: string;
  skin: Skin;
  theme: Design;
}) {
  return tipUsMarkup || bylineMarkup
    ? `
      <footer class="${footerContainerStyle({
        hasByline: !!bylineMarkup,
        skin,
        theme,
      })}">
        ${tipUsMarkup}
        ${bylineMarkup}
      </footer>`
    : '';
}
