import { TeaserType, ImageData, VideoData } from '../types';

import { getVideoPreview } from './getVideoPreview';
import { renderBrickImage } from '@amedia/brick-image/template';

function getAoiWidth(imgAoi): string {
  if (imgAoi) {
    return Number.isNaN(imgAoi.widthPercentage) ? '' : imgAoi.widthPercentage;
  }

  return '';
}

function getAutolayoutImage(
  imageData: ImageData,
  teaserType: TeaserType,
  aoiwidth: string
) {
  return `<amedia-img type='${teaserType}' aoiwidth='${aoiwidth}' alt="" srcset='${imageData.srcset}'></amedia-img>`;
}

export function getBrickImage(imageData: ImageData, isRendered?: boolean) {
  const tagAttributes: string[] = [];
  if (imageData.url) {
    tagAttributes.push(`data-src="${imageData.url}"`);
  }
  if (imageData.srcset) {
    tagAttributes.push(`data-srcset="${imageData.srcset}"`);
  }
  if (imageData.sizes) {
    tagAttributes.push(`data-sizes="${imageData.sizes}"`);
  }
  if (imageData.height && imageData.width) {
    tagAttributes.push(`data-height="${imageData.height}"`);
    tagAttributes.push(`data-width="${imageData.width}"`);
  }

  if (isRendered) {
    return renderBrickImage({
      dataSrcsize: '480',
      dataSrc: imageData.url,
      dataSrcset: imageData.srcset ? imageData.srcset : '',
      dataSizes: imageData.sizes ? imageData.sizes : '',
      dataLoading: 'lazy',
      dataAltText: '',
      dataWidth: imageData?.width?.toString(),
      dataHeight: imageData?.height?.toString(),
    });
  }

  return `<brick-image-v4 data-loading="lazy" ${tagAttributes.join(' ')} data-alt-text=""></brick-image-v4>`;
}

export function getImageTag({
  imageData,
  title,
  useAutolayout,
  useBrickImage,
  teaserType,
  isRendered,
}: {
  imageData: ImageData;
  title: string;
  useAutolayout?: boolean;
  useBrickImage?: boolean;
  teaserType?: TeaserType;
  videoPreview?: VideoData;
  isRendered?: boolean;
}) {
  const aoiwidth = getAoiWidth(imageData.imgAoi);

  let imageElement = '';
  if (useBrickImage) {
    imageElement = getBrickImage(imageData, isRendered);
  } else if (useAutolayout && teaserType && !useBrickImage) {
    imageElement = getAutolayoutImage(imageData, teaserType, aoiwidth);
  } else {
    imageElement = getBrickImage(imageData, isRendered);
  }

  if (imageData.preview || imageData.video) {
    const url = imageData.video?.url;
    return getVideoPreview({
      url: url,
      previewUrl: imageData.preview,
      title,
      imageElement,
    });
  }

  return imageElement;
}
