import { renderBrickIcon } from '@amedia/brick-icon/template';
import { Design } from '@amedia/brick-themes';

import { PremiumVersion } from '../../types';

import { premiumStyle } from './premium-style.js';

export default function getPremiumIcon({
  theme,
  premiumVersion,
  filled,
}: {
  theme: Design;
  premiumVersion: PremiumVersion;
  filled: boolean;
}) {
  const premiumMarkup = `<div class="premium-icon ${premiumStyle({
    theme,
    version: premiumVersion,
    filled,
  })}">${renderBrickIcon({
    dataIconId: premiumVersion,
    dataIconText: 'Artikkelen er for abonnenter',
  })}</div>`;

  return premiumMarkup;
}
