import { mapToTeaser } from './api/teaserApi';
import teaserTemplate from './teaserTypes/teaser';
import type { RenderOptions, Teaser, TeaserApi, ToggleOptions } from './types';
import { brickTeaserElement as brickTeaserStyle } from './styles';
import { carouselContextStyles } from './carousel-context-styles';

const camelToAttr = (str) =>
  str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);

function getToggleOptionEntries(data?: ToggleOptions) {
  if (!data || Object.entries(data).length === 0) {
    return;
  }
  const newData = {};
  for (const [key, value] of Object.entries(data)) {
    newData[`toggle-${camelToAttr(key)}`] = value;
  }
  return newData;
}

//We dont use the strings array, but we need it to be able to use the tagged template literal
function renderedTemplate(
  strings,
  brickTeaserClass,
  carouselContextStyles,
  attrs,
  markup
) {
  let datasetAttributes = '';

  if (attrs) {
    datasetAttributes = Object.entries(attrs).reduce((newObj, [key, val]) => {
      return `${newObj} ${key}="${val}"`;
    }, '');
  }

  return `<brick-teaser-v17 is-rendered class="${brickTeaserClass} ${carouselContextStyles}" ${datasetAttributes}>${markup}</brick-teaser-v17>`;
}

export const brickTeaserTemplate = (
  teaserData: Teaser,
  options: RenderOptions = {},
  isRendered?: boolean
) => {
  if (
    teaserData.teaserType === 'opinion' &&
    teaserData.version === 'imageLeft' &&
    (teaserData.theme === 'nettavisen' || teaserData.theme === 'bravo')
  ) {
    return '';
  }

  options.isRendered = isRendered;

  const markup = teaserTemplate(teaserData, options);

  const {
    externalId,
    breakingNews,
    amediaReferrer,
    theme,
    videoMeta,
    teaserType,
    id,
  } = teaserData;
  const toggleOptionEntries = getToggleOptionEntries(teaserData.toggleOptions);
  const { playOnFront } = videoMeta || {};

  const attrs = {
    ...(amediaReferrer && { 'data-amedia-referrer': `${amediaReferrer}` }),
    ...(externalId && { 'data-external-id': externalId }),
    ...(teaserType && { 'data-teaser-type': teaserType }),
    ...(id && { 'data-id': id }),
    ...(breakingNews?.breakingNews && {
      'data-breaking-news-text': breakingNews?.breakingNewsText || '',
    }),
    ...(toggleOptionEntries &&
      Object.keys(toggleOptionEntries).length > 0 && {
        ...toggleOptionEntries,
      }),
    ...(theme && { 'data-theme': theme }),
    ...(playOnFront && { 'data-video-play': `${playOnFront}` }),
  };

  // If isRendered==='true' it means the markup is rendered serverside, so we do not want to update the DOM client side, but we rende
  // brick-teaser element with the markup as a child.
  if (isRendered === true) {
    return renderedTemplate`${brickTeaserStyle} ${carouselContextStyles} ${attrs} ${markup}`;
  }
  // if not, we return the markup that should be attached to the DOM client side.
  return ` 
      ${markup}
    `;
};

export function renderBrickTeaser(
  teaserData: TeaserApi,
  renderOptions?: RenderOptions
) {
  const data: Teaser = mapToTeaser(teaserData);
  const isRendered = true;

  return brickTeaserTemplate(data, renderOptions, isRendered);
}
export { getCssText } from '@amedia/brick-tokens';
